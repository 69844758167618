.chat-container {
    width: 90%;
    max-width: 800px;
    margin: 10px auto;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #e0e0ef;
    display: flex;
    flex-direction: column;
}

.chat-messages, .chat-form {
    width: 100%;
}

.chat-header {
    color: #A100FF;
    margin-bottom: 5px;
    text-align: center; /* Centra il titolo */
    font-size: 16px;
    font-weight: bold;
    padding: 0px 0;
    font-family: 'Monserrat', sans-serif;
    font-weight: 700
}

.chat-messages {
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #A100FF; /* Bordo leggero per i messaggi */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-top: 0px;
    margin-bottom: 20px;
    background-color: #ffffff;
    text-align: left;
}

.bot-message, .user-message {
    font-size: 16px;
    margin-bottom: 5px;
}

.bot-message strong, .user-message strong {
    font-weight: bold;
    color: #A100FF;
}

.bot-message {
    color: #646464;
}

.user-message {
    color: #000;
}

.chat-form {
    display: flex;
    align-items: center;
    gap: 10px; /* Distanza tra gli elementi del form */
    flex-grow: 1; /**/
    margin-bottom: 10px;
}

.chat-input {
    /* flex-grow: 1; */
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #A100FF; /* Bordo stiile viola Accenture */
}

.button-group {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* padding: 10px; */
    gap: 10px;
    margin-top: 10px;
}

.input-and-buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.button {
    padding: 8px 12px;
    background-color: #A100FF;
    color: white;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #8e00e0;
}

.reset-button {
    margin-left: 20px;
}

.summary-button, .voice-button, .reset-button {
    background-color: #A100FF;
    border: none;
}

.change-scenary-button, .reset-endpoint-button {
    background-color: #A100FF;
    border: none;
}

.summary-button:hover, .voice-button:hover, .reset-button:hover {
    background-color: #8e00e0;
}

.change-scenary-button:hover, .reset-endpoint-button:hover {
    background-color: #8e00e0;
}

.additional-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 10px; */
}

.scenario-buttons, .navigation-buttons {
    display: flex;
    justify-content: center;
    /* gap: 10px; */
    /* margin-bottom: 10px; */
}

.button-group, .scenario-buttons, .navigation-buttons {
    margin-bottom: 10px;
}

.alma-virtual-assistant {
    color: black;
    font-family: 'Monserrat', sans-serif;
    font-size: 50px;
    position: relative;
}

.first-letter {
    color: black;
    font-weight: 700;
    position: relative;
}

.symbol-above {
    position: absolute;
    color: #A100FF;
    top: -25px;
    left: 0;
    font-size: 40px;
    font-weight: bold;
}

/* hr {
    border: 0;
    height: 1px;
    background-color: #ccc;
    margin: 10px 0;
} */

.section-title {
    display: flex;
    align-items: center;
    /* color: #333; */
    color: black;
    font-family: 'Monserrat', sans-serif;
    font-size: 16px;    
    margin: 10px 0;
}

.section-title hr {
    flex-grow: 1;
    border: 0;
    height: 1px;
    background-color: #ccc;
    margin: 0 10px;
}

.toggle-polly-button.enabled {
    background-color: #A100FF;
}

.toggle-polly-button.enabled:hover {
    background-color: #8e00e0;
}

.toggle-polly-button.disabled {
    background-color: #DC3545;
}

.toggle-polly-button.disabled:hover {
    background-color: #c82333;
}

.toggle-endpoint-button.enabled {
    background-color: #A100FF;
}

.toggle-endpoint-button.enabled:hover {
    background-color: #8e00e0;
}

.toggle-endpoint-button.disabled {
    background-color: #DC3545;
}

.toggle-endpoint-button.disabled:hover {
    background-color: #c82333;
}